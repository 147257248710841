
import JnMassimarioSearch from "@/views/search/MassimarioSearch.vue";
import JnCardsStrip from "@/views/common/CardsStrip.vue";
import JnSearchResults from "@/views/search/SearchResults.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { SearchData } from "@/models/search.model";
import { Testata } from '@/models/testata.model';
import MetaInfo from "vue-meta";
import {
  Massimario,
  Sections,
  RoutesToCategories,
  RoutesToNames,
} from "@/models/enums.model";
import { EsService } from "@/services/es.service";
import JnMainNewsGroup from "@/views/common/MainNewsGroup.vue";
import EventBus from "@/services/event.bus";
import { Pagination } from "@/models/pagination.model";
import { Document } from "@/models/document.model";
import JnDoc from "@/views/common/DocCard.vue";

@Component({
  components: {
    JnMassimarioSearch,
    JnCardsStrip,
    JnSearchResults,
    JnMainNewsGroup,
    JnDoc
  },
  metaInfo(this: JnMassimario): MetaInfo {
    return {
      title: "JuraNews - Massimario",
    };
  },
})
export default class JnMassimario extends Vue {

  testata?: Testata;

  searchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    keywords: [],
    perPage: 20,
    from: 0,
    searchScope: "massimario",
  });

  cassazioneCivilePagination?: Pagination;
  cassazioneCivileAllDocs: Document[] = [];
  cassazioneCivileShownDocs: Document[] = [];

  cassazionePenalePagination?: Pagination;
  cassazionePenaleAllDocs: Document[] = [];
  cassazionePenaleShownDocs: Document[] = [];

  cassazioneMeritoPagination?: Pagination;
  cassazioneMeritoAllDocs: Document[] = [];
  cassazioneMeritoShownDocs: Document[] = [];

  corteCostituzionalePagination?: Pagination;
  corteCostituzionaleAllDocs: Document[] = [];
  corteCostituzionaleShownDocs: Document[] = [];

  giustiziaAmministrativaPagination?: Pagination;
  giustiziaAmministrativaAllDocs: Document[] = [];
  giustiziaAmministrativaShownDocs: Document[] = [];

  cassazioneCivileSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Massimario.CASSASIONE_CIVILE],
    withText: true,
  });
  cassazionePenaleSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Massimario.CASSASIONE_PENALE],
    withText: true,
  });
  cassazioneMeritoSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Massimario.CASSASIONE_MERITO],
    withText: true,
  });
  corteCostituzionaleSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Massimario.CORTE_COSTITUZIONALE],
    withText: true,
  });
  giustiziaAmministrativaSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Massimario.GIUSTIZIA_AMMINISTRATIVA],
    withText: true,
  });

  async beforeMount() {
    this.testata = JnService.getTestata(Sections.MASSIMARIO);

    this.cassazioneCivileAllDocs = await EsService.getDocs(this.cassazioneCivileSearchData);

    this.cassazioneCivilePagination = new Pagination({
      page: 1,
      total: this.cassazioneCivileAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showCassazioneCivile(0, this.cassazioneCivilePagination.perPage);

    this.cassazionePenaleAllDocs = await EsService.getDocs(
      this.cassazionePenaleSearchData
    );
    this.cassazionePenalePagination = new Pagination({
      page: 1,
      total: this.cassazionePenaleAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showCassazionePenale(0, this.cassazionePenalePagination.perPage);

    this.cassazioneMeritoAllDocs = await EsService.getDocs(
      this.cassazioneMeritoSearchData
    );
    this.cassazioneMeritoPagination = new Pagination({
      page: 1,
      total: this.cassazioneMeritoAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showCassazioneMerito(0, this.cassazioneMeritoPagination.perPage);

    this.corteCostituzionaleAllDocs = await EsService.getDocs(
      this.corteCostituzionaleSearchData
    );
    this.corteCostituzionalePagination = new Pagination({
      page: 1,
      total: this.corteCostituzionaleAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showCorteCostituzionale(0, this.corteCostituzionalePagination.perPage);

    this.giustiziaAmministrativaAllDocs = await EsService.getDocs(
      this.giustiziaAmministrativaSearchData
    );
    this.giustiziaAmministrativaPagination = new Pagination({
      page: 1,
      total: this.giustiziaAmministrativaAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showGiustiziaAmministrativa(0, this.giustiziaAmministrativaPagination.perPage);

  }

  updated() {
    EventBus.$emit("search-results", []);
  }

  // Cassazione Civile
  nextCassazioneCivile() {
    if (this.cassazioneCivilePagination) {
      const from =
        (this.cassazioneCivilePagination.page - 1) * this.cassazioneCivilePagination.perPage;
      const size = this.cassazioneCivilePagination.perPage;
      this.showCassazioneCivile(from, size);
    }
  }
  showCassazioneCivile(from: number, size: number) {
    this.cassazioneCivileShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.cassazioneCivileAllDocs[i]) {
        this.cassazioneCivileShownDocs.push(this.cassazioneCivileAllDocs[i]);
      }
    }
  }

  // Cassazione Penale
  nextCassazionePenale() {
    if (this.cassazionePenalePagination) {
      const from =
        (this.cassazionePenalePagination.page - 1) *
        this.cassazionePenalePagination.perPage;
      const size = this.cassazionePenalePagination.perPage;
      this.showCassazionePenale(from, size);
    }
  }
  showCassazionePenale(from: number, size: number) {
    this.cassazionePenaleShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.cassazionePenaleAllDocs[i]) {
        this.cassazionePenaleShownDocs.push(this.cassazionePenaleAllDocs[i]);
      }
    }
  }

  // Corti di Merito
  nextCassazioneMerito() {
    if (this.cassazioneMeritoPagination) {
      const from =
        (this.cassazioneMeritoPagination.page - 1) *
        this.cassazioneMeritoPagination.perPage;
      const size = this.cassazioneMeritoPagination.perPage;
      this.showCassazioneMerito(from, size);
    }
  }
  showCassazioneMerito(from: number, size: number) {
    this.cassazioneMeritoShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.cassazioneMeritoAllDocs[i]) {
        this.cassazioneMeritoShownDocs.push(this.cassazioneMeritoAllDocs[i]);
      }
    }
  }

  // Corte Costituzionale
  nextCorteCostituzionale() {
    if (this.corteCostituzionalePagination) {
      const from =
        (this.corteCostituzionalePagination.page - 1) *
        this.corteCostituzionalePagination.perPage;
      const size = this.corteCostituzionalePagination.perPage;
      this.showCorteCostituzionale(from, size);
    }
  }
  showCorteCostituzionale(from: number, size: number) {
    this.corteCostituzionaleShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.corteCostituzionaleAllDocs[i]) {
        this.corteCostituzionaleShownDocs.push(this.corteCostituzionaleAllDocs[i]);
      }
    }
  }

  // Giustizia Amministrativa
  nextGiustiziaAmministrativa() {
    if (this.giustiziaAmministrativaPagination) {
      const from =
        (this.giustiziaAmministrativaPagination.page - 1) *
        this.giustiziaAmministrativaPagination.perPage;
      const size = this.giustiziaAmministrativaPagination.perPage;
      this.showGiustiziaAmministrativa(from, size);
    }
  }
  showGiustiziaAmministrativa(from: number, size: number) {
    this.giustiziaAmministrativaShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.giustiziaAmministrativaAllDocs[i]) {
        this.giustiziaAmministrativaShownDocs.push(this.giustiziaAmministrativaAllDocs[i]);
      }
    }
  }
}
