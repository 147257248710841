
import { Component, Prop, Vue } from "vue-property-decorator";
import JnDoc from "@/views/common/DocCard.vue";
import { Document } from "@/models/document.model";
import { SearchData } from "@/models/search.model";
import { EsService } from "@/services/es.service";
import { Pagination } from "@/models/pagination.model";
import EventBus from "@/services/event.bus";
import { JnCardsStripServices } from "./CardsStrip";

@Component({
  components: { JnDoc },
})
export default class JnCardsStrip extends Vue {
  @Prop({ required: true })
  searchData!: SearchData;

  @Prop({ required: false, default: true })
  showPagination!: boolean;

  pagination?: Pagination;

  allDocs: Document[] = [];
  shownDocs: Document[] = [];

  async mounted() {
    EventBus.$on("updateHomeCards", async () => {
      this.allDocs = await EsService.getDocs(this.searchData);
      this.pagination = new Pagination({
        page: 1,
        total: this.allDocs.length,
        perPage: this.searchData.perPage!,
        visible: 8,
      });
      this.show(0, this.pagination.perPage);
    });

    await JnCardsStripServices.pollData();
  }

  next() {
    if (this.pagination) {
      const from = (this.pagination.page - 1) * this.pagination.perPage;
      const size = this.pagination.perPage;
      this.show(from, size);
    }
  }

  show(from: number, size: number) {
    this.shownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.allDocs[i]) {
        this.shownDocs.push(this.allDocs[i]);
      }
    }
  }

  // Polling data feed
  polling: any;
  asyncpollData() {
    this.polling = setInterval(async () => {
      this.allDocs = await EsService.getDocs(this.searchData);
      this.show(0, this.pagination!.perPage);
    }, Number(process.env.VUE_APP_DATA_FEED_REFRESH_RATE));
  }
}
